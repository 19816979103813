import React, {createContext, useReducer, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from "jwt-decode";
import {Users} from "../services/API/Users";

// Create the context
export const UserContext = createContext();

const initialState = {
    id: '',
    displayName: '',
    email: '',
    blocked: '',
    active: '',
    role: '',
    company: {id: '', companyName: ''},
    isAuthenticated: false,
};

// Action types
const SET = 'SET';
const CLEAR = 'CLEAR';


// Reducer function
const userReducer = (state, action) => {
    console.log(action.payload.company);
    switch (action.type) {
        case SET:
            return {
                ...state,
                id: action.payload.id,
                displayName: action.payload.displayName,
                email: action.payload.email,
                blocked: action.payload.blocked,
                active: action.payload.active,
                role: action.payload.role,
                company: action.payload.company,
                isAuthenticated: true,
                isAdmin: action.payload.role === 'Administrator',
                isEmployee: action.payload.role === 'ImEfficiency' || action.payload.company.id === 1 ? true : false,
            };
        case CLEAR:
            return initialState;
        default:
            return state;
    }
};


// UserProvider component to wrap your app with
const UserProvider = ({children}) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const history = useHistory();

    const initializeUser = async () => {
        const authToken = Cookies.get('authToken');
        const licenseAgreement = Cookies.get('licenseAgreement');

        const isExcludedRoute =
            window.location.pathname.startsWith('/auth/account-activation') ||
            window.location.pathname.startsWith('/auth/password-recovery') ||
            window.location.pathname.startsWith('/auth/password-reset');

        if (!isExcludedRoute && (!authToken || !licenseAgreement)) {
            history.push("/auth/login"); // Redirect to login for authenticated routes without valid cookies (except excluded routes)
            return;
        }

        if (authToken && licenseAgreement) {
            const decodedToken = jwtDecode(authToken);
            const expirationDate = new Date(decodedToken.exp * 1000); // Convert seconds to milliseconds
            const currentDate = new Date();

            if (expirationDate < currentDate) {
                history.push("/auth/login");
                return;
            }

            try {
                const resp = await Users.CurrentUser();
                const {id, displayName, email, blocked, active, roles, companies} = resp.data;

                if (roles.length > 0 && companies.length > 0) {
                    dispatch({
                        type: SET,
                        payload: {
                            id,
                            displayName,
                            email,
                            blocked,
                            active,
                            role: roles[0],
                            company: companies[0],
                            isAuthenticated: true,
                        },
                    });
                } else {
                    // Handle case when roles or companies arrays are empty
                    history.push("/auth/login");
                }
            } catch (error) {
                // Handle error fetching user data from API
                history.push("/auth/login");
            }
        }
    };

    useEffect(() => {
        initializeUser();
    }, []); // Run once after the initial render to check authentication

    return (
        <UserContext.Provider value={{state, dispatch}}>
            {children}
        </UserContext.Provider>
    );
};

export {UserProvider};